import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import { Hero, Container } from 'reactbulma'

import Layout from '../components/layouts/index'
import SEO from '../components/seo'

import './style.scss'

import '../utils/typography'

const { Fragment } = React

class PrivacyPage extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout
        location={this.props.location}
        className="index"
        snipperLogo={this.props.data.snipperLogo.childImageSharp.fluid}
      >
        <SEO
          title="SnipperApp 2 Privacy Statement"
          titleTemplate={data => `%s – ${data.site.siteMetadata.name} privacy`}
          description="✅ The SnipperApp 2 Privacy Statement ⭐ Explains what does personal data SnipperApp 2 collects and how the company uses it"
        />
        <Fragment>
          <Hero fullheight>
            <Hero.Body>
              <Container>
                <h1 className="title is-1 faq-title">
                  {post.frontmatter.title}
                </h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </Container>
            </Hero.Body>
          </Hero>
        </Fragment>
      </Layout>
    )
  }
}

PrivacyPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
}

export default PrivacyPage

export const pageQuery = graphql`
  query PrivacyBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
      }
    }
    snipperLogo: file(
      absolutePath: { regex: "/snipper-logo-512×512@2x.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
